import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'
import Radio from '../button/Radio'
import { windowSizeBreakpoints } from 'utils/breakpoints'

export interface RadioOption {
  id: string
  label: string
}
interface FormRadioButtonProps {
  title: string
  options: RadioOption[]
  selectedOption?: RadioOption
  onOptionSelected: (option: RadioOption) => void
  mandatory?: boolean
  marginRight?: number
}

export const FormRadioButton = ({
  onOptionSelected,
  options,
  selectedOption,
  title,
  mandatory,
  marginRight,
}: FormRadioButtonProps) => (
  <ItemContainer>
    <InputCategoryContainer>
      <InputCategoryTitle mandatory={mandatory}>{title}</InputCategoryTitle>
    </InputCategoryContainer>
    <RadioButtons>
      {options.map((opt) => (
        <RadioButtonContainer key={opt.id} marginRight={marginRight} onClick={() => onOptionSelected(opt)}>
          <Radio
            value={opt.id === selectedOption?.id}
            onChange={(val) => {
              if (val) {
                onOptionSelected(opt)
              }
            }}
          />
          <RadioButtonTitle>{opt.label}</RadioButtonTitle>
        </RadioButtonContainer>
      ))}
    </RadioButtons>
  </ItemContainer>
)

interface FormTextInputProps {
  title: string
  placeholder?: string
  value: string
  id: string
  error?: string
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
  name: string
  onChange: React.ChangeEventHandler<HTMLTextAreaElement> | undefined
  mandatory?: boolean
  titleAriaLabel?: string
  maxLength?: number
  isKilometers?: boolean
  defaultHeight?: number
}

export const FormTextInput = ({
  title,
  placeholder,
  value,
  name,
  onChange,
  id,
  error,
  inputMode,
  mandatory,
  titleAriaLabel,
  defaultHeight,
  isKilometers,
  maxLength,
}: FormTextInputProps) => {
  const inputRef = React.useRef<HTMLTextAreaElement>(null)

  // useEffect pour ajuster la taille des textarea
  React.useEffect(() => {
    if (inputRef && inputRef.current) {
      if (value.length === 0) {
        inputRef.current.style.height = `${defaultHeight || 45}px`
      } else {
        inputRef.current.style.height = '0px'
        const scrollHeight = inputRef.current.scrollHeight
        inputRef.current.style.height = scrollHeight + 'px'
      }
    }
  }, [value, defaultHeight])

  React.useEffect(() => {
    if (inputRef && inputRef.current && value.length > 0 && !!isKilometers) {
      const onlyNumber = value.replace(/\D/g, '')
      const addSpaces = onlyNumber.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      const positionCurseur = addSpaces.length
      if (inputRef.current) {
        inputRef.current.setSelectionRange(positionCurseur, positionCurseur)
      }
    }
  }, [value, isKilometers])

  return (
    <ItemContainer>
      <InputTitleContainer>
        <InputCategoryTitle htmlFor={id} mandatory={mandatory} aria-label={titleAriaLabel}>
          {title}
        </InputCategoryTitle>
      </InputTitleContainer>
      <InputTextContainer>
        <InputField
          ref={inputRef}
          id={id}
          value={isKilometers ? value.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : value}
          placeholder={placeholder || title}
          name={name}
          onChange={onChange}
          inputMode={inputMode}
          spellCheck={false}
          autoCorrect="off"
          autoCapitalize="off"
          aria-required="true"
          aria-describedby={`${id}Error`}
          maxLength={maxLength || 500}
        />
      </InputTextContainer>
      <ErrorText id={`${id}Error`}>{error}</ErrorText>
    </ItemContainer>
  )
}

interface FormValuePickerProps {
  title: string
  placeholder?: string
  value?: string
  id: string
  onClick?: () => void
  withArrow?: boolean
  mandatory?: boolean
  footerContent?: JSX.Element
  mainContent?: JSX.Element
  ariaLabel?: string
  disabled?: boolean
}

export const FormValuePicker = ({
  title,
  placeholder,
  value,
  id,
  onClick,
  withArrow,
  mandatory,
  footerContent,
  mainContent,
  ariaLabel,
  disabled,
}: FormValuePickerProps) => {
  const [theme] = useTheme()
  return (
    <ItemContainer>
      {mainContent ? (
        mainContent
      ) : (
        <React.Fragment>
          <InputTitleContainer>
            <InputCategoryTitle htmlFor={id} mandatory={mandatory} disabled={disabled}>
              {title}
            </InputCategoryTitle>
          </InputTitleContainer>
          <InputCategoryContainer
            clickable={!!onClick && !disabled}
            onClick={!disabled ? onClick : undefined}
            aria-label={ariaLabel}>
            <Value id={id} filled={!!value} disabled={disabled}>
              {value || placeholder}
            </Value>
            {withArrow && (
              <IconContainer>
                <Icon name="chevron_bottom" color={theme.colors.middleGrey} size={15} />
              </IconContainer>
            )}
          </InputCategoryContainer>
        </React.Fragment>
      )}

      {footerContent}
    </ItemContainer>
  )
}

interface PickerOption {
  label: string
  value: any
}

interface FormValuesPickerProps {
  id: string
  title: string
  values: PickerOption[]
  removeOption: (label: string) => void
  placeholder: string
  onClick: () => void
  disabled?: boolean
  mandatory?: boolean
  ariaLabel?: string
  noMarginTop?: boolean
}

export const FormValuesPicker = ({
  id,
  title,
  values,
  removeOption,
  placeholder,
  onClick,
  disabled,
  mandatory,
  ariaLabel,
  noMarginTop,
}: FormValuesPickerProps) => {
  const [theme] = useTheme()

  return (
    <ItemContainer maxWidth={300} noMarginTop={noMarginTop}>
      <InputCategoryContainer onClick={!disabled ? onClick : undefined} clickable={!disabled} aria-label={ariaLabel}>
        <InputTitleContainer>
          <InputCategoryTitle htmlFor={id} disabled={disabled} mandatory={mandatory}>
            {title}
          </InputCategoryTitle>
        </InputTitleContainer>
        <IconContainer>
          <Icon name="plus" color={disabled ? theme.colors.middleGrey : theme.colors.black} size={25} />
        </IconContainer>
      </InputCategoryContainer>
      {values.length > 0 ? (
        <MultipleValuesContainer id={id}>
          {values.map((opt) => (
            <SingleValueContainer key={opt.label} onClick={() => removeOption(opt.label)}>
              <SingleValueText>{opt.label}</SingleValueText>
              <Icon name="cross" color={theme.colors.darkGrey} size={14} />
            </SingleValueContainer>
          ))}
        </MultipleValuesContainer>
      ) : (
        <Value id={id} filled={false} disabled={disabled} onClick={!disabled ? onClick : undefined}>
          {placeholder}
        </Value>
      )}
    </ItemContainer>
  )
}

const ItemContainer = styled('div')<{ maxWidth?: number; noMarginTop?: boolean }>`
  display: flex;
  ${(props) => props.maxWidth && `max-width: ${props.maxWidth}px`};
  flex-direction: column;
  margin: 10px 0px;
  ${(props) => props.noMarginTop && 'margin-top: 0px;'}
  min-width: 300px;
  max-width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 100%;
  }
`

const InputCategoryContainer = styled('button')<{ clickable?: boolean }>`
  display: flex;
  cursor: ${(props) => (props.clickable ? 'pointer' : 'default')};
  justify-content: space-between;
`

const InputTextContainer = styled('div')`
  display: flex;
  justify-content: space-between;
`

const InputTitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const InputCategoryTitle = styled('label')<{ disabled?: boolean; mandatory?: boolean }>`
  display: flex;
  ${(props) => props.theme.fonts.body};
  color: ${(props) => (props.disabled ? props.theme.colors.middleGrey : props.theme.colors.darkGrey)};
  margin: 0px 10px 10px 0px;
  ${(props) =>
    props.mandatory &&
    `
    ::after {
      color: ${props.theme.colors.red};
      content: "*";
      position: relative;
      left: 4px;
    }
  `}
`

const InputField = styled('textarea')`
  resize: none;
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px;
  height: 0px;
  padding: 4px 0px;
  border: 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
  outline: 0;
  ::placeholder {
    font-weight: 300;
  }
  &:focus {
    border-color: ${(props) => props.theme.colors.blue};
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    min-width: 80vw;
  }
`

const Value = styled('p')<{ filled?: boolean; disabled?: boolean }>`
  display: flex;
  ${(props) => (props.filled ? props.theme.fonts.bodyBold : props.theme.fonts.body)};
  color: ${(props) => (props.filled ? props.theme.colors.blue : props.theme.colors.middleGrey)};
  margin: 0px;
  flex: 1;
  text-align: start;
`

const IconContainer = styled('div')`
  padding-left: 10px;
`

const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.red};
  margin: 0px;
  margin-top: 10px;
`

const MultipleValuesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  gap: 5px;
`

const SingleValueContainer = styled('button')`
  background: ${(props) => props.theme.colors.mediumDarkGrey};
  border-radius: 4px;
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const SingleValueText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-right: 5px;
`

const RadioButtons = styled('div')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`

const RadioButtonContainer = styled('div')<{ marginRight?: number }>`
  margin-right: ${(props) => props.marginRight || 35}px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const RadioButtonTitle = styled('p')`
  ${(props) => props.theme.fonts.body};
  padding-left: 8px;
`
