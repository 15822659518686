import { I18n } from 'i18n/types'
import { InterventionValues } from './InterventionScreen'
import wordings from './wordings.json'
import wordingsFleet from './wordingsFleet.json'
import config from 'core/src/config'
import { FleetValues } from './FleetScreen'

const getColorFromStatus = (status: string) => {
  if (status === 'SUBMITTED' || status === 'APPROVED' || status === 'READY' || status === 'ASSIGNED') {
    return 'blue'
  } else if (status === 'CANCELLED' || status === 'REJECTED') {
    return 'red'
  } else if (status === 'CLOSED' || status === 'COMPLETE' || status === 'RESOLVED') {
    return 'turquoise'
  }
  return 'orange'
}

const imageMimeTypes: { [key in AttachmentImageFormat]: string } = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
}

const documentMimeTypesIntervention: { [key in AttachmentDocumentFormatIntervention]: string } = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const documentMimeTypes: { [key in AttachmentDocumentFormat]: string } = {
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

const isFleetFormValid = (values: FleetValues, formType: string) => {
  if (formType === 'type1') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.userDeliveringVehiclePhoneNumber &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLocation &&
      !!values.vehicleLicensePlate &&
      !!values.currentVehicleKilometers &&
      !!values.technicalRevisionExpirationDate &&
      !!values.vehicleWithdrawalDate &&
      !!values.additionalCorrectiveAction &&
      !!values.onSiteVehicleRemoval &&
      !!values.replacementVehicleRequired
    )
  }
  if (formType === 'type2') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.userDeliveringVehiclePhoneNumber &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLocation &&
      !!values.vehicleLicensePlate &&
      !!values.repair &&
      !!values.vehicleReasonProblem &&
      !!values.vehicleWithdrawalDate &&
      !!values.onSiteVehicleRemoval &&
      !!values.replacementVehicleRequired
    )
  }
  if (formType === 'type3') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLocation &&
      !!values.ocl &&
      !!values.vehicleNeededDate &&
      !!values.vehicleDuration &&
      !!values.vehicleTraction &&
      !!values.equipmentsAdditional
    )
  }
  if (formType === 'type4') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLicensePlate &&
      !!values.ocl &&
      !!values.equipmentNeededDate &&
      !!values.onSiteVehicleRemoval &&
      (values.onSiteVehicleRemoval.id === '1' || !!values.vehicleLocation) &&
      !!values.equipmentsAdditional
    )
  }
  if (formType === 'type5') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLicensePlate &&
      !!values.typeDocument &&
      (values.typeDocument !== 'OTHERS' || !!values.otherInfos)
    )
  }
  if (formType === 'type6') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.applicantUserPhoneNumber &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLocation &&
      !!values.vehicleLicensePlate &&
      !!values.ocl &&
      !!values.applicantManagement &&
      !!values.cardRequestType
    )
  }
  if (formType === 'type7') {
    return (
      !!values.userDeliveringVehicle &&
      !!values.applicantUserPhoneNumber &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLicensePlate &&
      !!values.rut
    )
  }
  if (formType === 'type8') {
    return (
      !!values.userInvolvedAccident &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLicensePlate &&
      !!values.claimNumber &&
      !!values.claimDeclarationAttachment &&
      !!values.complaintProofAttachment &&
      !!values.policeStatementAttachment &&
      !!values.identityCardAttachment &&
      !!values.driverLicenseAttachment
    )
  }
  if (formType === 'type9') {
    return (
      !!values.userInvolvedAccident &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLicensePlate &&
      !!values.claimNumber &&
      !!values.claimDeclarationAttachment &&
      !!values.identityCardAttachment &&
      !!values.driverLicenseAttachment
    )
  }
  if (formType === 'type10') {
    return (
      !!values.userInvolvedAccident &&
      !!values.responsibleVehicleUser &&
      !!values.vehicleLicensePlate &&
      !!values.thirdPartyVehiclePlateNumber &&
      !!values.claimNumber &&
      !!values.claimDeclarationAttachment &&
      !!values.complaintProofAttachment &&
      !!values.identityCardAttachment &&
      !!values.driverLicenseAttachment
    )
  }
  return false
}

const isSpecificFormValid = (values: InterventionValues, formType: string) => {
  if (formType === 'type1') {
    return !!values.entity && !!values.ceco && !!values.employeeId && !!values.manager
  }
  if (formType === 'type2') {
    return !!values.employee
  }
  if (formType === 'type3') {
    return !!values.carLicensePlate && !!values.visitDuration && !!values.visitorsNames
  }
  if (formType === 'type4') {
    return true
  }
  if (formType === 'type5') {
    return !!values.callerId && !!values.numberOfPeople && !!values.affection && !!values.comment
  }
  if (formType === 'type6') {
    return !!values.employee
  }
  if (formType === 'type7') {
    return (
      !!values.company &&
      !!values.estimatedCheckInCheckOut &&
      !!values.visitorsNameAndSurnames &&
      !!values.responsible &&
      !!values.visitReason
    )
  }
  if (formType === 'type8') {
    return !!values.placeNumber && !!values.numberPlate
  }
  if (formType === 'type9') {
    return !!values.typeRequest && !!values.employee && !!values.typeRooms && !!values.requestReason
  }
  if (formType === 'type10') {
    return (
      !!values.selectedFloor &&
      !!values.company &&
      !!values.estimatedCheckInCheckOut &&
      !!values.visitorsNameAndSurnames &&
      !!values.responsible &&
      !!values.visitReason
    )
  }
  return false
}

const formatDescriptionSpecificForm = (
  values: InterventionValues,
  formType: string,
  connectedUser: UserSelf,
  currentSite: SiteV6,
  i18n: I18n
) => {
  const formatDate = i18n.t('screens.incident.form.spain.formatDate', {
    date: values.date,
  })
  const formatEntryDate = i18n.t('screens.incident.form.spain.formatDate', {
    date: values.entryDate,
  })
  const formatDepartureDate = i18n.t('screens.incident.form.spain.formatDate', {
    date: values.departureDate,
  })
  const formatEstimatedEndDate = i18n.t('screens.incident.form.spain.formatDate', {
    date: values.estimatedEndDate,
  })
  const formatEstimatedCheckInCheckOut = i18n.t('screens.incident.form.spain.formatDate', {
    date: values.estimatedCheckInCheckOut,
  })
  const formattedTime = `${values.time.hours.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${values.time.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`
  const formattedEntryTime = `${values.entryTime.hours.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${values.entryTime.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`
  const formattedDepartureTime = `${values.departureTime.hours.toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  })}:${values.departureTime.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 })}`
  if (formType === 'type1') {
    return `Entity : ${values.entity}\nCECO : ${values.ceco}\nEmployee ID : ${values.employeeId}\nRequester : ${
      values.requester?.lastname || connectedUser.lastName
    } ${values.requester?.firstname || connectedUser.firstName}\nRequester Email : ${
      values.requester?.email || connectedUser.email
    }\nOpen by : ${connectedUser.lastName} ${connectedUser.firstName}\nManager : ${
      values.manager?.engieId
    }\nManager Email : ${values.manager?.email}`
  }
  if (formType === 'type2') {
    return `Employee : ${values.employee?.engieId}\nEmployee email : ${values.employee?.email}`
  }
  if (formType === 'type3') {
    return `Car License plate : ${values.carLicensePlate}\nDate : ${formatDate}\nTime : ${formattedTime}\nDuration of the visit : ${values.visitDuration}\nVisitor’s Names (Do not include DNI) : ${values.visitorsNames}`
  }
  if (formType === 'type4') {
    return `Requester : ${values.requester?.lastname || connectedUser.lastName} ${
      values.requester?.firstname || connectedUser.firstName
    }\nRequester Email : ${values.requester?.email || connectedUser.email}\nOpen by : ${connectedUser.lastName} ${
      connectedUser.firstName
    }\nEntry : ${formatEntryDate} ${formattedEntryTime}\nDeparture : ${formatDepartureDate} ${formattedDepartureTime}`
  }
  if (formType === 'type5') {
    return `Site : ${currentSite.name}\nCaller ID : ${values.callerId?.engieId}\nNumber of affected personnes : ${
      wordings.type5.numberOfPeople[values.numberOfPeople!]
    }\nAffection : ${wordings.type5.affection[values.affection!]}\nComment : ${values.comment}`
  }
  if (formType === 'type6') {
    return `Employee : ${values.employee?.email}\nEntry Date : ${formatEntryDate}\nEstimated end date for access : ${formatEstimatedEndDate}`
  }
  if (formType === 'type7' || formType === 'type10') {
    return `Company : ${values.company}\nEntry Date : ${formatEntryDate}\nEstimated check in and check out time : ${formatEstimatedCheckInCheckOut}\nName and surname of visitors : ${values.visitorsNameAndSurnames}\nResponsible : ${values.responsible?.engieId}\nResponsible email : ${values.responsible?.email}\nReason for the visit : ${values.visitReason}`
  }
  if (formType === 'type8') {
    return `Employee : ${connectedUser.officeId}\nPlace number : ${values.placeNumber}\nNumber plate of the car occupying the space : ${values.numberPlate}`
  }
  if (formType === 'type9') {
    return `Type of request : ${wordings.type9.typeRequest[values.typeRequest!]}\nEmployee : ${
      values.employee?.engieId
    }\nEmployee email : ${values.employee?.email}\nRoom : ${
      wordings.type9.typeRooms[values.typeRooms!]
    }\nReason of the request : ${values.requestReason}`
  }
  return ''
}

const transformLevel = (level: number) => (level === -999 ? -0.5 : level)

const sortedFloors = (locations: LocationV6[]) => {
  // On veut placer l'étage -999 entre le -1 et le 0
  const findFloorOrder = locations.find((l) => !!l.levelOrder)
  if (!!findFloorOrder) {
    const transformLevelMapping: LocationV6[] = locations.map((l) => ({ ...l, level: transformLevel(l.level) }))
    const sortedFloors = transformLevelMapping.sort((a, b) => {
      // Priorité à ceux qui ont un levelOrder défini
      if (
        a.levelOrder !== undefined &&
        a.levelOrder !== null &&
        (b.levelOrder === undefined || b.levelOrder === null)
      ) {
        return -1
      } else if (
        (a.levelOrder === undefined || a.levelOrder === null) &&
        b.levelOrder !== undefined &&
        b.levelOrder !== null
      ) {
        return 1
      } else if (
        a.levelOrder !== undefined &&
        a.levelOrder !== null &&
        b.levelOrder !== undefined &&
        b.levelOrder !== null
      ) {
        // Si les deux ont levelOrder défini, les trier par levelOrder
        return a.levelOrder - b.levelOrder
      } else {
        // Sinon, les trier par level
        return a.level - b.level
      }
    })
    const sortedByBuilding = sortedFloors.sort((a, b) => {
      const buildingNameA = a.buildingName ?? ''
      const buildingNameB = b.buildingName ?? ''
      return buildingNameA.localeCompare(buildingNameB)
    })
    return sortedByBuilding
  } else {
    const sortedFloors = locations
      .sort((a, b) => transformLevel(a.level) - transformLevel(b.level))
      .sort((a, b) => {
        const buildingNameA = a.buildingName ?? ''
        const buildingNameB = b.buildingName ?? ''
        return buildingNameA.localeCompare(buildingNameB)
      })
    return sortedFloors
  }
}

const findLevelWording = (floor: number, i18n: I18n, levelCustomName?: string) => {
  return !!levelCustomName
    ? levelCustomName
    : floor === -999
    ? '0'
    : i18n.t(floor === 0 ? 'screens.incident.groundFloor' : 'screens.room.floorNumber', {
        floor,
      })
}

const generateFleetList = () => {
  const FIRST_LIST: FleetForm[] = [
    ...config.fleetSysId.type1.map((id) => ({ id, type: 'type1' })),
    ...config.fleetSysId.type2.map((id) => ({ id, type: 'type2' })),
  ]
  const SECOND_LIST: FleetForm[] = [
    ...config.fleetSysId.type3.map((id) => ({ id, type: 'type3' })),
    ...config.fleetSysId.type4.map((id) => ({ id, type: 'type4' })),
    ...config.fleetSysId.type5.map((id) => ({ id, type: 'type5' })),
    ...config.fleetSysId.type6.map((id) => ({ id, type: 'type6' })),
    ...config.fleetSysId.type7.map((id) => ({ id, type: 'type7' })),
  ]
  const THIRD_LIST: FleetForm[] = [
    ...config.fleetSysId.type8.map((id) => ({ id, type: 'type8' })),
    ...config.fleetSysId.type9.map((id) => ({ id, type: 'type9' })),
    ...config.fleetSysId.type10.map((id) => ({ id, type: 'type10' })),
  ]
  return {
    FIRST_LIST,
    SECOND_LIST,
    THIRD_LIST,
  }
}

const formatDescriptionFleet = (values: FleetValues, formType: string, i18n: I18n) => {
  const formatTechnicalRevisionExpirationDate = i18n.t('screens.fleet.formatDateDescription', {
    date: values.technicalRevisionExpirationDate,
  })

  const formatVehicleWithdrawalDate = i18n.t('screens.fleet.formatDateDescription', {
    date: values.vehicleWithdrawalDate,
  })

  const formatVehicleNeededDate = i18n.t('screens.fleet.formatDateDescription', {
    date: values.vehicleNeededDate,
  })

  const formatEquipmentNeededDate = i18n.t('screens.fleet.formatDateDescription', {
    date: values.equipmentNeededDate,
  })

  if (formType === 'type1') {
    return `Usuario que entrega vehículo : ${
      values.userDeliveringVehicle
    }\nTeléfono contacto usuario que entrega vehículo : ${
      values.userDeliveringVehiclePhoneNumber
    }\nUsuario responsable vehículo : ${values.responsibleVehicleUser?.firstname} ${
      values.responsibleVehicleUser?.lastname
    }\nSitio ubicación vehículo : ${values.vehicleLocation?.label}\nPatente vehículo : ${
      values.vehicleLicensePlate?.label
    }\nKilometraje actual del vehículo : ${
      values.currentVehicleKilometers
    } km\nVencimiento revisión técnica : ${formatTechnicalRevisionExpirationDate}\nFecha retiro vehículo : ${formatVehicleWithdrawalDate}\nRequiere acción correctiva adicional : ${
      values.additionalCorrectiveAction
    }\nRequiere retiro de vehículo en sitio : ${
      wordingsFleet.onSiteVehicleRemoval[
        values.onSiteVehicleRemoval!.id as keyof typeof wordingsFleet.onSiteVehicleRemoval
      ]
    }\nRequiere vehículo de reemplazo : ${
      wordingsFleet.replacementVehicleRequired[
        values.replacementVehicleRequired!.id as keyof typeof wordingsFleet.replacementVehicleRequired
      ]
    }`
  }
  if (formType === 'type2') {
    return `Usuario que entrega vehículo : ${
      values.userDeliveringVehicle
    }\nTeléfono contacto usuario que entrega vehículo : ${
      values.userDeliveringVehiclePhoneNumber
    }\nUsuario responsable vehículo : ${values.responsibleVehicleUser?.firstname} ${
      values.responsibleVehicleUser?.lastname
    }\nSitio ubicación vehículo : ${values.vehicleLocation?.label}\nPatente vehículo : ${
      values.vehicleLicensePlate?.label
    }\nReparación : ${
      wordingsFleet.repair[values.repair!.id as keyof typeof wordingsFleet.repair]
    }\nMotivo reparación o problema vehículo : ${
      values.vehicleReasonProblem
    }\nFecha retiro vehículo : ${formatVehicleWithdrawalDate}\nRequiere retiro de vehículo en sitio : ${
      wordingsFleet.onSiteVehicleRemoval[
        values.onSiteVehicleRemoval!.id as keyof typeof wordingsFleet.onSiteVehicleRemoval
      ]
    }\nRequiere vehículo de reemplazo : ${
      wordingsFleet.replacementVehicleRequired[
        values.replacementVehicleRequired!.id as keyof typeof wordingsFleet.replacementVehicleRequired
      ]
    }`
  }
  if (formType === 'type3') {
    return `Usuario solicitante : ${values.userDeliveringVehicle}\nUsuario responsable vehículo : ${
      values.responsibleVehicleUser?.firstname
    } ${values.responsibleVehicleUser?.lastname}\nSitio de entrega vehículo : ${values.vehicleLocation?.label}\nOCL : ${
      values.ocl?.label
    }\nFecha necesidad vehículo : ${formatVehicleNeededDate}\nTiempo necesidad de vehículo : ${
      values.vehicleDuration
    }\nTracción vehículo : ${
      wordingsFleet.vehicleTraction[values.vehicleTraction!.id as keyof typeof wordingsFleet.vehicleTraction]
    }\nDetalle de equipamiento adicional al estánda : ${values.equipmentsAdditional}`
  }
  if (formType === 'type4') {
    return `Usuario solicitante : ${values.userDeliveringVehicle}\nUsuario responsable vehículo : ${
      values.responsibleVehicleUser?.firstname
    } ${values.responsibleVehicleUser?.lastname}\nPatente vehículo : ${values.vehicleLicensePlate?.label}\nOCL : ${
      values.ocl?.label
    }\nFecha necesidad vehículo : ${formatEquipmentNeededDate}\nRequiere retiro de vehículo : ${
      wordingsFleet.onSiteVehicleRemoval[
        values.onSiteVehicleRemoval!.id as keyof typeof wordingsFleet.onSiteVehicleRemoval
      ]
    }\n${
      !!values.vehicleLocation &&
      !!values.onSiteVehicleRemoval &&
      values.onSiteVehicleRemoval.id === '0' &&
      `Sitio de entrega de vehículo : ${values.vehicleLocation?.label}\n`
    }Detalle de equipamiento adicional al estándar : ${values.equipmentsAdditional}`
  }
  if (formType === 'type5') {
    return `Usuario solicitante : ${values.userDeliveringVehicle}\nUsuario responsable vehículo : ${
      values.responsibleVehicleUser?.firstname
    } ${values.responsibleVehicleUser?.lastname}\nPatente vehículo : ${
      values.vehicleLicensePlate?.label
    }\nTipo documento : ${wordingsFleet.typeDocument[values.typeDocument!]}\n${
      !!values.typeDocument && values.typeDocument === 'OTHERS' ? `Otros : ${values.otherInfos}\n` : ''
    }`
  }
  if (formType === 'type6') {
    return `Usuario solicitante : ${values.userDeliveringVehicle}\nTeléfono usuario solicitante : ${
      values.applicantUserPhoneNumber
    }\nUsuario responsable vehículo : ${values.responsibleVehicleUser?.firstname} ${
      values.responsibleVehicleUser?.lastname
    }\nSitio donde necesita la tarjeta : ${values.vehicleLocation?.label}\nPatente vehículo : ${
      values.vehicleLicensePlate?.label
    }\nOCL : ${values.ocl?.label}\nGerencia solicitante : ${
      values.applicantManagement?.label
    }\nTipos de solicitud de tarjeta : ${
      wordingsFleet.cardRequestType[values.cardRequestType!.id as keyof typeof wordingsFleet.cardRequestType]
    }`
  }
  if (formType === 'type7') {
    return `Usuario solicitante : ${values.userDeliveringVehicle}\nTeléfono usuario solicitante : ${values.applicantUserPhoneNumber}\nUsuario responsable vehículo : ${values.responsibleVehicleUser?.firstname} ${values.responsibleVehicleUser?.lastname}\nPatente vehículo solicitante : ${values.vehicleLicensePlate?.label}\nRUT usuario solicitante : ${values.rut}\n`
  }
  if (formType === 'type8' || formType === 'type9') {
    return `Usuario responsable vehículo : ${values.responsibleVehicleUser?.firstname} ${values.responsibleVehicleUser?.lastname}\nUsuario involucrado en siniestro : ${values.userInvolvedAccident}\nPatente vehículo : ${values.vehicleLicensePlate?.label}\nNúmero de siniestro : ${values.claimNumber}`
  }
  if (formType === 'type10') {
    return `Usuario responsable vehículo : ${values.responsibleVehicleUser?.firstname} ${values.responsibleVehicleUser?.lastname}\nUsuario involucrado en siniestro : ${values.userInvolvedAccident}\nPatente vehículo : ${values.vehicleLicensePlate?.label}\nPatente vehículo tercero : ${values.thirdPartyVehiclePlateNumber}\nNúmero de siniestro : ${values.claimNumber}`
  }
  return ''
}
export interface FleetForm {
  id: string
  type: string
}

export type TypeRequest = 'ACCESS_REQUEST' | 'ACCESS_CANCEL'
export type TypeAffection = 'COMPLETLY_INTERRUPTED' | 'PARTIALLY_INTERRUPTED' | 'AFFECTED'
export type TypeNumberPeople = 'ONE_PERSON' | 'TWO_TO_TEN_PEOPLE' | 'MORE_THAN_TEN_PEOPLE'
export type TypeRooms = 'CPD_IT' | 'SHORT_TERM_ROOM' | 'TRADING_ROOM'

const TYPE_REQUEST_OPTIONS: TypeRequest[] = ['ACCESS_REQUEST', 'ACCESS_CANCEL']
const TYPE_AFFECTION_OPTIONS: TypeAffection[] = ['COMPLETLY_INTERRUPTED', 'PARTIALLY_INTERRUPTED', 'AFFECTED']
const TYPE_NUMBER_PEOPLE_OPTIONS: TypeNumberPeople[] = ['ONE_PERSON', 'TWO_TO_TEN_PEOPLE', 'MORE_THAN_TEN_PEOPLE']
const TYPE_ROOMS_OPTIONS: TypeRooms[] = ['CPD_IT', 'SHORT_TERM_ROOM', 'TRADING_ROOM']

// Fleet

export type TypeRadioButtonBoolean = 'yes' | 'no'
export type TypeRepair = 'CLAIM' | 'FIX'
export type TypeTraction = '4X4' | '4X2'
export type TypeVehicleDuration = '12' | '24' | '36' | '48'
export type TypeDocument = 'TECHNICAL_INSPECTION' | 'VEHICLE_REGISTRATION' | 'SOAP' | 'CERTIFICATIONS' | 'OTHERS'
export type TypeCardRequest = 'NEW' | 'REPLACEMENT' | 'BLOCKING'

const TYPE_RADIO_BUTTON_BOOLEAN: TypeRadioButtonBoolean[] = ['yes', 'no']
const TYPE_REPAIR: TypeRepair[] = ['CLAIM', 'FIX']
const TYPE_TRACTION: TypeTraction[] = ['4X4', '4X2']
const TYPE_VEHICLE_DURATION: TypeVehicleDuration[] = ['12', '24', '36', '48']
const TYPE_DOCUMENT: TypeDocument[] = [
  'TECHNICAL_INSPECTION',
  'VEHICLE_REGISTRATION',
  'SOAP',
  'CERTIFICATIONS',
  'OTHERS',
]
const TYPE_CARD_REQUEST: TypeCardRequest[] = ['NEW', 'REPLACEMENT', 'BLOCKING']

export {
  getColorFromStatus,
  imageMimeTypes,
  documentMimeTypesIntervention,
  documentMimeTypes,
  isSpecificFormValid,
  formatDescriptionSpecificForm,
  sortedFloors,
  transformLevel,
  findLevelWording,
  generateFleetList,
  isFleetFormValid,
  formatDescriptionFleet,
  TYPE_NUMBER_PEOPLE_OPTIONS,
  TYPE_AFFECTION_OPTIONS,
  TYPE_REQUEST_OPTIONS,
  TYPE_ROOMS_OPTIONS,
  TYPE_RADIO_BUTTON_BOOLEAN,
  TYPE_REPAIR,
  TYPE_TRACTION,
  TYPE_VEHICLE_DURATION,
  TYPE_DOCUMENT,
  TYPE_CARD_REQUEST,
}
