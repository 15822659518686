import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'
import Switch from 'react-switch'

interface Props {
  value: boolean
  setValue: React.Dispatch<React.SetStateAction<boolean>> | ((b: boolean) => void)
  label?: string
  offColor?: string
  onColor?: string
  icon?: string // Utilisé pour Yammer (format .png)
}

const SwitchFilter = ({ value, setValue, label, offColor, onColor, icon }: Props) => {
  const [Theme] = useTheme()

  return (
    <Container
      tabIndex={0}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setValue(!value)
          e.preventDefault()
        }
      }}>
      <Switch
        checkedIcon={false}
        uncheckedIcon={false}
        handleDiameter={16}
        onColor={onColor || Theme.colors.blue}
        offColor={offColor || Theme.colors.middleGrey}
        checked={value}
        onChange={() => setValue(!value)}
        height={20}
        width={39}
        tabIndex={-1}
        aria-checked={undefined}
      />
      {icon && <IconImage src={icon} alt="" />}
      {label && (
        <Label
          onClick={() => setValue(!value)}
          activeColor={onColor || Theme.colors.blue}
          offColor={offColor || Theme.colors.middleGrey}
          checked={value}>
          {label}
        </Label>
      )}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 0;
  background-color: transparent;
`
const IconImage = styled('img')`
  width: 22px;
  height: 22px;
  margin-left: 10px;
  margin-bottom: 2px;
`
const Label = styled.p<{ activeColor: string; offColor: string; checked: boolean }>`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => (props.checked ? props.activeColor : props.offColor)};
  line-height: 19px;
  margin-left: 10px;
`
export default SwitchFilter
