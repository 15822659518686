import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import { showedDate } from 'news/utils'
import { format, addDays } from 'date-fns'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import Button from 'components/button/Button'
import Icons from 'components/icons/Icons'
import LabelWithIcon from 'components/label/LabelWithIcon'
import Modal from 'components/modal/Modal'
import ModalContent from 'components/modal/ValidationContent'
import NewsImage from 'news/NewsImage'
import ZoomImage from 'components/image/ZoomImage'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import CarouselRework from 'components/image/CarouselRework'

import * as store from '../store'
import api from '../api'
import { executeGraphRequest } from 'authent365/utils/utils'
import useNavigation from 'utils/navigation/useNavigation'
import utils from 'utils/strings'
import useBreakpoint from 'utils/useBreakpoints'

import YammerImage from 'news/img/yammer.png'

import Loader from 'react-loader-spinner'
import { formatDistanceToNow } from 'date-fns'

interface Props {
  id: string
}

interface Position {
  x: number
  y: number
}

// En dessous de cette width, les images ne sont plus affichées à droite
// mais dans un caroussel en dessous du titre
export const MAX_IMAGE_WINDOW_WIDTH = 1300

export const WIDTH_IMAGE_RIGHT = 492
export const HEIGHT_IMAGE_RIGHT = 300
export const HEIGHT_IMAGE_LEFT = 250

const NewsDetails = ({ id }: Props) => {
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const [windowSize] = useBreakpoint()
  const [article, setArticle] = React.useState<NewsDetailV6>()
  const [status, setStatus] = React.useState<'loading' | 'error' | 'ok'>('loading')
  const [photoURLs, setPhotoURLs] = React.useState<CarouselItem[]>([])
  const [calendarLoading, setCalendarLoading] = React.useState(false)

  const [position, setPosition] = React.useState({ x: 0, y: 0 })
  const [showText, setShowText] = React.useState(false)

  const imagesOnRight = React.useMemo(() => windowSize > MAX_IMAGE_WINDOW_WIDTH, [windowSize])

  const i18n = useI18n()
  const liked = useReducer(store.store, (s) => s.liked)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const isLiked = liked.indexOf(id) >= 0
  const user = useReducer(userStore.store, (s) => s.user)

  React.useEffect(() => {
    load()
    if (site && user) {
      api.article(id, site.id, user.type, 'en').then((res) => {
        analytics.track({
          screenName: analyticsKeys.newsDetails,
          userType: user.type,
          currentSite: site,
          mySites,
          label: res.title,
        })
      })
    }
  }, [i18n.lang])

  // Met à jour la position de la souris
  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setPosition({ x: e.pageX, y: e.pageY })
  }

  // Affiche le texte lorsqu'on survole l'élément
  const handleMouseEnter = () => {
    setShowText(true)
  }

  // Cache le texte lorsqu'on quitte l'élément
  const handleMouseLeave = () => {
    setShowText(false)
  }

  const load = (displayLoading?: boolean) => {
    if (displayLoading) {
      setStatus('loading')
    }
    if (site && user) {
      api
        .article(id, site.id, user.type)
        .then((res) => {
          if (res) {
            setArticle(res)
            const photoUrls = res.photos
              .filter((p) => !!p.url)
              .sort((p1, p2) => p1.position - p2.position)
              .map((p) => ({ url: p.url, key: `KEY_${p.position}`, description: p.description }))
            setPhotoURLs(photoUrls)
            setStatus('ok')
          } else {
            setStatus('error')
          }
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  const toggleLike = () => {
    if (site && user) {
      const action =
        liked.indexOf(id) < 0
          ? api.like(id, site.id, user.type).then(() => store.actions.setLiked([...liked, id]))
          : api.unlike(id, site.id, user.type).then(() => store.actions.setLiked(liked.filter((l) => l !== id)))

      action.then(() => load()).catch(Logger.error)
    }
  }

  const addToCalendar = () => {
    setCalendarLoading(true)
    if (article && user && site) {
      if (!article.startDate || !article.endDate) {
        return null
      }

      const startDate = `${format(new Date(article.startDate), 'yyyy-MM-dd')}T${
        (!article.entireDay && article.startTime) || '00:00:00'
      }`
      const endDate = `${format(
        !!article.entireDay ? addDays(new Date(article.endDate), 1) : new Date(article.endDate),
        'yyyy-MM-dd'
      )}T${(!article.entireDay && article.endTime) || '00:00:00'}`

      const event = {
        start: {
          dateTime: startDate,
          timeZone: site.timezone,
        },
        end: {
          dateTime: endDate,
          timeZone: site.timezone,
        },
        isAllDay: !!article.entireDay,
        subject: article.title,
        location: {
          displayName: article.location || '',
        },
      }

      executeGraphRequest(user.type, '/me/events', 'POST', event)
        .then(() =>
          Modal.open(() => (
            <ModalContent
              title={i18n.t(`screens.news.calendarSucceed`)}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        )
        .catch(() =>
          Modal.open(() => (
            <ModalContent
              title={i18n.t(`screens.news.calendarFailed`)}
              onConfirm={Modal.close}
              ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            />
          ))
        )
        .finally(() => setCalendarLoading(false))
    }
  }

  const openInYammer = () => (article ? window.open(article.webUrl) : undefined)

  const openPDF = (pdf: NewsPDFV6) => {
    if (!!pdf.associatedButtonName && !!pdf.url) {
      if (pdf.url.endsWith('.pdf')) {
        navigation.push('/pdf', {
          pdfUrl: pdf.url,
          pdfTitle: pdf.associatedButtonName,
        })
      } else {
        window.open(`/${i18n.lang}/${pdf.url}`)
      }
    }
    return null
  }

  const onEnterKey = (e: React.KeyboardEvent, onClickFunc: () => void) => {
    if (e.key === 'Enter') {
      onClickFunc()
    }
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error' || !article) {
    return (
      <CenteredView imagesOnRight>
        <NoNewsError>{i18n.t('screens.news.detail.error')}</NoNewsError>
        <ErrorButtonContainer>
          <Button label={i18n.t('common.retry')} onClick={() => load(true)} font={Theme.fonts.h3Bold} />
        </ErrorButtonContainer>
      </CenteredView>
    )
  }

  const displayedDate =
    article.type === 'INFO'
      ? i18n.t('screens.news.publicationDate', { date: new Date(article.publicationDate) })
      : !!article.startDate && !!article.endDate
      ? showedDate(i18n, new Date(article.startDate), new Date(article.endDate))
      : undefined

  return (
    <>
      <TitleHelmet title={article.title || i18n.t('screens.news.title')} />
      <MainContainer>
        <Breadcrumb screen="news" path="news" details={article.title} navigation={navigation} />
        <NewsMainContainer>
          <CenteredView imagesOnRight={imagesOnRight}>
            <ArticleTag>{i18n.t(`screens.news.tags.${article.tag}`)}</ArticleTag>

            <ArticleTitle>{article.title}</ArticleTitle>

            {article.type === 'EVENT' && (
              <EventInfosContainer>
                {article.startDate && article.endDate && (
                  <TopLabelContainer>
                    <LabelWithIcon
                      text={displayedDate || ''}
                      iconName="agenda"
                      colorIcon={Theme.colors.blue}
                      colorText={Theme.colors.blue}
                    />
                  </TopLabelContainer>
                )}
                {article.startTime && article.endTime && (
                  <TopLabelContainer>
                    <LabelWithIcon
                      text={i18n.t('screens.news.timeEvent', {
                        start: article.startTime.replace(':', 'h').replace('h00', 'h').replace(':00', ''),
                        end: article.endTime.replace(':', 'h').replace('h00', 'h').replace(':00', ''),
                      })}
                      iconName="clock"
                      colorIcon={Theme.colors.blue}
                      colorText={Theme.colors.blue}
                    />
                  </TopLabelContainer>
                )}
                {article.location && (
                  <TopLabelContainer>
                    <LabelWithIcon
                      text={article.location}
                      iconName="pin"
                      colorIcon={Theme.colors.blue}
                      colorText={Theme.colors.blue}
                    />
                  </TopLabelContainer>
                )}
              </EventInfosContainer>
            )}

            {(article.lifecycle === 'DRAFT' || article.lifecycle === 'APPROVED') && (
              <StatusContainer>
                <Icons name="pencil" color={Theme.colors.blue} size={25} />
                <StatusText>
                  {i18n.t(`screens.news.${article.lifecycle === 'DRAFT' ? 'DRAFT' : 'scheduledDate'}`, {
                    date: new Date(article.publicationDate),
                  })}
                </StatusText>
              </StatusContainer>
            )}
            {!imagesOnRight && photoURLs.length > 0 && (
              <ImagesContainer imagesOnRight={imagesOnRight}>
                <ImageContainer imagesOnRight={imagesOnRight}>
                  <CarouselRework
                    fullWidth
                    height={HEIGHT_IMAGE_LEFT}
                    photosUrl={photoURLs.map((photo) => photo.url)}
                  />
                </ImageContainer>
              </ImagesContainer>
            )}
            <InfoBar>
              <BottomLabelContainer
                onMouseMove={handleMouseMove}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onKeyDown={(e) => onEnterKey(e, toggleLike)}
                aria-label={i18n.t(`label.ariaLabel.news.${isLiked ? 'notLike' : 'like'}Article`)}>
                <LabelWithIcon
                  text={i18n.t('screens.news.likesRework', {
                    count: article.nbLikes,
                  })}
                  onClick={toggleLike}
                  iconName={isLiked ? 'heart-filled' : 'heart'}
                  colorIcon={Theme.colors.blue}
                  colorText={Theme.colors.iconGrey}
                />
              </BottomLabelContainer>
              {showText && (
                <HoverTextContainer position={position}>
                  <HoverText>{i18n.t(`common.${!isLiked ? 'like' : 'unlike'}`)}</HoverText>
                </HoverTextContainer>
              )}
              {article.type === 'EVENT' && (
                <BottomLabelContainer
                  onKeyDown={(e) => onEnterKey(e, addToCalendar)}
                  aria-label={i18n.t('label.ariaLabel.news.saveInCalendar')}>
                  <LabelWithIcon
                    text={i18n.t('screens.news.addToCalendar')}
                    onClick={addToCalendar}
                    iconName="agenda"
                    colorIcon={Theme.colors.blue}
                    colorText={Theme.colors.iconGrey}
                    loading={calendarLoading}
                  />
                </BottomLabelContainer>
              )}
              {article.tag === 'YAMMER' && (
                <YammerLabelContainer onClick={openInYammer}>
                  <YammerIcon src={YammerImage} alt="" />
                  <Label>{i18n.t('screens.news.seeYammer')}</Label>
                </YammerLabelContainer>
              )}

              {article.pdfs && article.pdfs.length > 0 && (
                <>
                  {article.pdfs
                    .filter((pdf) => !!pdf.associatedButtonName && !!pdf.url)
                    .map((pdf) => (
                      <BottomLabelContainer
                        key={pdf.url}
                        onKeyDown={(e) => onEnterKey(e, () => openPDF(pdf))}
                        aria-label={pdf.associatedButtonName}>
                        <LabelWithIcon
                          text={pdf.associatedButtonName as string}
                          onClick={() => openPDF(pdf)}
                          iconName="file-article"
                          colorIcon={Theme.colors.blue}
                          colorText={Theme.colors.iconGrey}
                          underline
                        />
                      </BottomLabelContainer>
                    ))}
                </>
              )}
            </InfoBar>

            {article.content && (
              <Content
                dangerouslySetInnerHTML={{ __html: article.content }}
                tabIndex={0}
                onClick={(evt) => {
                  const target = evt.target as HTMLAnchorElement
                  const url = target.href
                  if (!!url) {
                    externalLinkTracking(url, analyticsKeys.newsDetails, user, site, mySites)
                  }
                }}
              />
            )}
            {article.lifecycle !== 'APPROVED' && article.lifecycle !== 'DRAFT' && (
              <ArticleDate>
                {utils.capitalize(
                  i18n.t('screens.news.publicationDate', {
                    duration: formatDistanceToNow(new Date(article.publicationDate), {
                      locale: i18n.locale,
                    }),
                  })
                )}
              </ArticleDate>
            )}
          </CenteredView>
          {imagesOnRight && (
            <ImagesContainer imagesOnRight={imagesOnRight}>
              {photoURLs.map((photo) => (
                <ImageContainer key={photo.key} imagesOnRight={imagesOnRight}>
                  <NewsImage
                    onClick={() => {
                      if (site) {
                        ZoomImage.open(photo.url)
                      }
                    }}
                    height={HEIGHT_IMAGE_RIGHT}
                    width={WIDTH_IMAGE_RIGHT}
                    imageURL={photo.url}
                  />
                </ImageContainer>
              ))}
            </ImagesContainer>
          )}
        </NewsMainContainer>
      </MainContainer>
    </>
  )
}

export default NewsDetails

// GENERAL

const MainContainer = styled('div')`
  margin-right: 4%;
`
const NewsMainContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
const CenteredView = styled.div<{ imagesOnRight: boolean }>`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-top: 40px;
  width: ${(props) => (props.imagesOnRight ? '55%' : '100%')};
  color: ${(props) => props.theme.colors.middleGrey};

  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-left: 56px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    margin-left: 16px;
  }
`
const ImagesContainer = styled('div')<{ imagesOnRight: boolean }>`
  display: flex;
  flex-direction: column;
  ${(props) => props.imagesOnRight && 'margin-left: 28px;'}
  ${(props) => !props.imagesOnRight && 'margin-top: 28px;'}
  width: ${(props) => (props.imagesOnRight ? `${WIDTH_IMAGE_RIGHT}px` : '100%')};
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const ImageContainer = styled('div')<{ imagesOnRight: boolean }>`
  width: ${(props) => (props.imagesOnRight ? `${WIDTH_IMAGE_RIGHT}px` : '100%')};
  height: ${(props) => (props.imagesOnRight ? `${HEIGHT_IMAGE_RIGHT}px` : `${HEIGHT_IMAGE_LEFT}px`)};
  cursor: pointer;
  ${(props) => !!props.imagesOnRight && 'margin-bottom: 32px;'}
  background-color: ${(props) => props.theme.colors.middleGrey};
`
const ErrorButtonContainer = styled('div')`
  display: flex;
  width: 300px;
`

// INFO

const ArticleTitle = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  font-size: 30px;
  line-height: 31px;
  padding-top: 12px;
  margin: 0;
`
const ArticleTag = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0;
`
const InfoBar = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 38px;
`
const NoNewsError = styled.p`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
`
const Content = styled.div`
  ${(props) => props.theme.fonts.body};
  margin-top: 34px;
  text-align: justify;
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 16px;
`
const TopLabelContainer = styled('div')`
  margin-right: 30px;
`
const BottomLabelContainer = styled('div')`
  padding-right: 30px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const YammerLabelContainer = styled('button')`
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 2px;
  &:hover {
    text-decoration: underline;
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const Label = styled('path')`
  ${(props) => props.theme.fonts.labelBold}
  font-size: 14px;
  line-height: 16px;
  color: ${(props) => props.theme.colors.iconGrey};
  margin-left: 5px;
`
const YammerIcon = styled('img')`
  width: 20px;
  height: 20px;
`
const ArticleDate = styled.p`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.turquoise};
  margin: 15px 0px;
`

const StatusContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 14px;
`

const StatusText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0;
  padding-left: 10px;
  color: ${(props) => props.theme.colors.middleGrey};
`

const EventInfosContainer = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 14px;
  flex-wrap: wrap;
`

const HoverTextContainer = styled('div')<{ position: Position }>`
  position: fixed;
  top: ${(props) => props.position.y + 30}px;
  left: ${(props) => props.position.x}px;
  background-color: ${(props) => props.theme.colors.black};
  border-radius: 4px;
  pointer-events: none;
  padding: 6px;
  white-space: nowrap;
`

const HoverText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  margin: 0px;
`
