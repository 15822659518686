import axios, { ResponseType } from 'axios'
import * as i18nStore from 'i18n/i18n'
import oauth2 from 'authent365/oauth/OAuth2'

const MAX_TIMEOUT = 20000

export class ApiError extends Error {
  code: number
  data: any
  constructor(public message: string, code: number, data?: any) {
    super(message)
    this.code = code
    this.data = data
  }
}

/* Pour un webservice spécifique qui ne renvoi pas de données, mais on a besoin du code de la réponse */
export const executeGetCode = <T>(
  userType: UserType,
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE',
  headers: { [key: string]: string } = {},
  params?: any,
  body?: any,
  isExternalWS?: boolean
): Promise<number | T> => {
  const lang = i18nStore.store.getState().lang

  // Si un WS est externel à living@ (appel sur un autre serveur (Sharvy par exemple)) on n'ajoute pas le header 'Structure'
  const heads: { [key: string]: string } = isExternalWS
    ? {
        'content-type': 'application/json',
        'Accept-Language': lang,
        ...headers,
      }
    : {
        'content-type': 'application/json',
        'Accept-Language': lang,
        Structure: userType !== 'EXTERNAL' ? userType : 'ALL',
        ...headers,
      }

  // cancel Token used for Timeout
  const cancelToken = axios.CancelToken.source()
  setTimeout(cancelToken.cancel, MAX_TIMEOUT)

  return axios
    .request<T>({
      cancelToken: cancelToken.token,
      url: path,
      method: method || (body ? 'POST' : 'GET'),
      headers: heads,
      data: body ? JSON.stringify(body) : undefined,
      timeout: MAX_TIMEOUT,
      params: params,
    })
    .then((res) => res.status)
    .catch((err) => {
      const { status, data, statusText } = err.response

      if (status === 401 || status === 403) {
        if (data.error === 'invalid_token' && userType === 'EXTERNAL') {
          return oauth2.getAccessToken().then((res) =>
            retryOrThrow<T>(userType, path, method, { Authorization: `Bearer ${res}` }, body).catch(() => {
              // Authentication or right error and max retry reached
              throw new ApiError('Expired session ?', status, data)
            })
          )
        }
        return retryOrThrow<T>(userType, path, method, headers, body).catch(() => {
          // Authentication or right error and max retry reached
          throw new ApiError('Expired session ?', status, data)
        })
      }
      if (status >= 400) {
        // Query or Server Error
        throw new ApiError('Unable to query ' + path + ' : ' + status + ' / ' + statusText, status, data)
      }

      // Network error (no internet, server down, ...)
      throw err
    })
}

const execute = <T>(
  userType: UserType,
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH',
  headers: { [key: string]: string } = {},
  params?: any,
  body?: any,
  isExternalWS?: boolean,
  responseType?: ResponseType
): Promise<T> => {
  const lang = i18nStore.store.getState().lang
  // Si un WS est externe à living@ (appel sur un autre serveur (Sharvy par exemple)) on ne doit pas avoir le header 'Structure'
  // Si jamais les WS internes viennent à évoluer avec de nouveaux headers, on garde les configuration bien distinctes
  const heads: { [key: string]: string } = isExternalWS
    ? {
        'Content-Type': 'application/json',
        'Accept-Language': lang,
        ...headers,
      }
    : {
        'Content-Type': 'application/json',
        'Accept-Language': lang,
        Structure: userType !== 'EXTERNAL' ? userType : 'ALL',
        ...headers,
      }

  // cancel Token used for Timeout
  const cancelToken = axios.CancelToken.source()
  setTimeout(cancelToken.cancel, MAX_TIMEOUT)

  return axios
    .request<T>({
      cancelToken: cancelToken.token,
      url: path,
      method: method || (body ? 'POST' : 'GET'),
      headers: heads,
      data: body ? (heads['Content-Type'] === 'application/json' ? JSON.stringify(body) : body) : undefined,
      timeout: MAX_TIMEOUT,
      params: params,
      responseType,
    })
    .then((res) => res.data)
    .catch((err) => {
      if (!err || !err.response) {
        throw new ApiError(`No response : timeout ? WS : ${path}  `, 408)
      }
      const { status, data, statusText } = err.response

      if (status === 401 || status === 403) {
        if (data.error === 'invalid_token' && userType === 'EXTERNAL') {
          return oauth2.getAccessToken().then((res) =>
            retryOrThrow<T>(userType, path, method, { Authorization: `Bearer ${res}` }, body).catch(() => {
              // Authentication or right error and max retry reached
              throw new ApiError('Expired session ?', status, data)
            })
          )
        }
        return retryOrThrow<T>(userType, path, method, headers, body).catch(() => {
          // Authentication or right error and max retry reached
          throw new ApiError('Expired session ?', status, data)
        })
      }
      if (status >= 400) {
        // Query or Server Error
        throw new ApiError('Unable to query ' + path + ' : ' + status + ' / ' + statusText, status, data)
      }

      // Network error (no internet, server down, ...)
      throw err
    })
}

const errors: { [api: string]: number } = {}
const MAX_ERROR = 2

/**
 * Retry api calls or break cycle if max error reached
 */
const retryOrThrow = <T>(
  userType: UserType,
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH',
  header: { [key: string]: string } = {},
  body?: any,
  isExternalWS?: boolean
): Promise<T> => {
  const key = `${path}/${method}`
  const errorCount = errors[key] || 0

  if (errorCount < MAX_ERROR) {
    // MAX not reached
    errors[key] = errorCount + 1
    return execute(userType, path, method, header, undefined, body, isExternalWS)
  }

  // MAX reached we throw an error
  return Promise.reject('Max try reached')
}

export default execute
