import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { I18n } from 'i18n/types'

import { useFormik } from 'formik'
import * as Yup from 'yup'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'

interface Props {
  onSelectContact: (email: string) => void
}

const emailValidationSchema = (i18n: I18n) =>
  Yup.object().shape({
    email: Yup.string().email(i18n.t('errors.form.email')).required(i18n.t('errors.form.mandatory')),
  })

const ExternalContact = ({ onSelectContact }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: (values: { email: string }) => onSelectContact(values.email),
    validationSchema: emailValidationSchema(i18n),
  })

  return (
    <MainContainer>
      <ExplanationContainer>
        <ExplanationText>{i18n.t('screens.directory.addExternalExplanation')}</ExplanationText>
      </ExplanationContainer>
      <FormContainer>
        <InputLabel htmlFor="externalGuestEmail">{i18n.t('screens.directory.email')}</InputLabel>
        <Input
          id="externalGuestEmail"
          autoComplete="email"
          aria-required="true"
          aria-describedby="externalGuestEmailError"
          type="email"
          name="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        <ErrorText id="externalGuestEmailError">{formik.touched.email && formik.errors.email}</ErrorText>
        <ButtonContainer>
          <Button
            type="submit"
            label={i18n.t('screens.directory.addExternal')}
            disabled={!(formik.isValid && formik.dirty)}
            onClick={formik.handleSubmit}
            font={Theme.fonts.h3Bold}
            verticalPadding={14}
          />
        </ButtonContainer>
      </FormContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: calc(100% - 22px);
  width: 375px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const ExplanationContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
`
const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 30px;
`

// TEXTES

const ExplanationText = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0px;
  width: 327px;
  color: ${(props) => props.theme.colors.Darkgrey};
  text-align: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`
const InputLabel = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 11px;
  line-height: 18px;
  margin-bottom: 4px;
  margin-top: 20px;
`
const ErrorText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.blue};
  min-height: 20px;
  margin: 0px;
`

// AUTRES

const FormContainer = styled('form')`
  display: flex;
  flex-direction: column;
  margin: 0px 24px;
`
const Input = styled.input`
  width: 289px;
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold}
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
  color: ${(props) => props.theme.colors.darkGrey};
  background: transparent;
  font-size: 15px;
  padding: 0px 0px 5px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`

export default ExternalContact
