import { createEvent, createStore } from 'effector'
import * as shortcutStore from 'home/store/shortcutStore'
import * as favoritesStore from 'transport/favoritesStore'
import * as roomStore from 'roombooking/roomStore'
import * as contactStore from 'directory/contactStore'
import * as themeStore from 'theme/store'

import equal from 'fast-deep-equal'

const resetStoreonNewUser = () => {
  shortcutStore.actions.reset()
  favoritesStore.actions.resetFav()
  roomStore.actions.resetFav()
  contactStore.actions.resetFav()
  themeStore.actions.reset()
}

export const actions = {
  setUser: createEvent<UserSelf>('setUser'),
  setSameUser: createEvent<boolean>('setSameuser'),
  resetUser: createEvent('resetUser'),
  reset: createEvent('reset'),
  setFavoriteUsers: createEvent<string[]>('setFavoriteUsers'),
  resetFavoriteUsers: createEvent('resetFavoriteUsers'),
  setBadges: createEvent<BadgeV5[]>('setBadges'),
  setFullLoader: createEvent<boolean>('setFullLoader'),
}

const initialState: UserState = {
  user: undefined,
  lastuser: undefined,
  sameUser: undefined,
  favoriteUsers: [],
  badges: [],
  lastBadgesRefresh: undefined,
  fullLoader: false,
}

export const store = createStore<UserState>(initialState, { name: 'User Info v2' })
  .on(actions.setUser, (s, user) => {
    if (s.lastuser !== user.id) {
      resetStoreonNewUser()
    }
    return {
      ...(s.lastuser === user.id ? s : initialState),
      sameUser: s.lastuser === user.id,
      user: equal(user, s.user) ? s.user : user,
      lastuser: user.id,
    }
  })
  .on(actions.setSameUser, (s, sameUser) => ({ ...s, sameUser }))
  .on(actions.resetUser, (s) => ({ ...s, user: undefined }))
  .on(actions.reset, () => ({ ...initialState }))
  .on(actions.setFavoriteUsers, (s, favoriteUsers) => ({ ...s, favoriteUsers }))
  .on(actions.resetFavoriteUsers, (s) => ({ ...s, favoriteUsers: initialState.favoriteUsers }))
  .on(actions.setBadges, (s, badges) => ({ ...s, badges, lastBadgesRefresh: new Date().toISOString() }))

export const fullLoaderStore = createStore<UserState>(initialState, { name: 'fullLoader' }).on(
  actions.setFullLoader,
  (s, fullLoader) => ({
    ...s,
    fullLoader,
  })
)
