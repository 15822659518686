import {
  getDay,
  isAfter,
  setHours,
  setMinutes,
  eachDayOfInterval,
  getWeek,
  isWeekend,
  addDays,
  isBefore,
  isSameDay,
  subBusinessDays,
  addBusinessDays,
} from 'date-fns'

import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz'
import config from 'core/src/config'

const findDay = (day: string, referenceWeek: ReferenceWeek) => {
  switch (day) {
    case 'MONDAY':
      return referenceWeek.monday
    case 'TUESDAY':
      return referenceWeek.tuesday
    case 'WEDNESDAY':
      return referenceWeek.wednesday
    case 'THURSDAY':
      return referenceWeek.thursday
    case 'FRIDAY':
      return referenceWeek.friday
    default:
      return referenceWeek.monday
  }
}

const getDayOfWeek = (date: Date) => {
  const dayIndex = getDay(date)
  switch (dayIndex) {
    case 1:
      return 'monday'
    case 2:
      return 'tuesday'
    case 3:
      return 'wednesday'
    case 4:
      return 'thursday'
    case 5:
      return 'friday'
    default:
      return 'monday'
  }
}

const filteredDays = (currentWeekDays: Date[]) =>
  currentWeekDays.filter((day) => isAfter(setHours(setMinutes(day, 59), 23), new Date()))

const getDatesWeekSelected = (start: Date, end: Date) => eachDayOfInterval({ start, end })

const findAvailabilityIcon = (status: Translation, isHotDeskInUse?: boolean, visitor?: boolean) =>
  status === 'ok' && !isHotDeskInUse
    ? 'check-circle'
    : status === 'ok' && isHotDeskInUse
    ? 'cross-circle'
    : status === 'zone_is_full_booked' && visitor
    ? 'cross-circle'
    : (status === 'zone_is_full_booked' && !isHotDeskInUse) || status === 'max_bookings_reached_provided_date'
    ? 'warning-circle'
    : 'cross-circle'

const findAvailabilityColor = (status: Translation, theme: Theme, isHotDeskInUse?: boolean, visitor?: boolean) =>
  status === 'ok' && !isHotDeskInUse
    ? theme.colors.blue
    : status === 'ok' && isHotDeskInUse
    ? theme.colors.red
    : status === 'zone_is_full_booked' && visitor
    ? theme.colors.red
    : (status === 'zone_is_full_booked' && !isHotDeskInUse) || status === 'max_bookings_reached_provided_date'
    ? theme.colors.orange
    : theme.colors.red

const findPriorityError = (errors: ErrorMessage[], visitor?: boolean): Translation => {
  if (errors.length === 1 && errors[0].translation === 'ok') {
    return 'ok'
  }
  if (visitor && !errors.find((err) => err.translation === 'zone_is_full_booked')) {
    return 'ok'
  }
  const waitingErrors = errors.filter((error) => getStatusErrorMessage(error.translation, visitor) === 'waiting')
  const refusedErrors = errors.filter((error) => getStatusErrorMessage(error.translation, visitor) === 'error')

  if (refusedErrors.length > 0) {
    return refusedErrors[0].translation
  }
  if (waitingErrors.length > 0) {
    return waitingErrors[0].translation
  }
  return 'weekday_not_available'
}

const findPercentageOccupation = (zone: ZoneAttributes) => Math.round((zone.desks_taken / zone.desk_count) * 100)

const findIconName = (percentageOccupation: number) =>
  percentageOccupation < 71 ? 'gauge-empty' : percentageOccupation < 100 ? 'gauge-middle' : 'gauge-full'

const findColorOccupation = (percentageOccupation: number, theme: Theme) =>
  percentageOccupation < 71 ? theme.colors.green : percentageOccupation < 100 ? theme.colors.orange : theme.colors.red

const filterAvailableBookings = (bookings: ZapfloorZoneRecurrence[], isRandomDeskActivated: boolean) =>
  bookings.filter((booking) => {
    const errorMessage = findPriorityError(booking.zone.attributes.error_response)
    if (getStatusErrorMessage(errorMessage) === 'waiting' && isRandomDeskActivated) {
      return true
    }
    if (getStatusErrorMessage(errorMessage) !== 'error' && !booking.zone.attributes.hot_desk_in_use) {
      return true
    }
    return false
  })

const filterNotAvailableBookings = (bookings: ZapFloorZoneRecurrence[]) =>
  bookings.filter((booking) => {
    const errorMessage = findPriorityError(booking.zone.attributes.error_response)

    if ((errorMessage === 'zone_is_full_booked' || errorMessage === 'ok') && booking.zone.attributes.hot_desk_in_use) {
      return true
    }
    return false
  })

const filterAvailableBookingsV2 = (bookings: ZapfloorMultipleZoneRecurrence[], isRandom?: boolean) =>
  bookings
    .map((booking) => {
      const filterErrors = booking.zones.filter((zone) => {
        const errorMessage = findPriorityError(zone.attributes.error_response)
        if (!isRandom && zone.attributes.hot_desk_in_use) {
          return false
        }
        if (getStatusErrorMessage(errorMessage) !== 'error') {
          return true
        }
        return false
      })
      return {
        date: booking.date,
        zones: filterErrors,
      }
    })
    .filter((booking) => booking.zones.length > 0)

const filterNotAvailableBookingsV2 = (bookings: ZapfloorMultipleZoneRecurrence[]) =>
  bookings
    .map((booking) => {
      const filterErrors = booking.zones.filter((zone) => {
        const errorMessage = findPriorityError(zone.attributes.error_response)
        if ((errorMessage === 'zone_is_full_booked' || errorMessage === 'ok') && zone.attributes.hot_desk_in_use) {
          return true
        }
        return false
      })
      return {
        date: booking.date,
        zones: filterErrors,
      }
    })
    .filter((booking) => booking.zones.length > 0)

const getStatusErrorMessage = (errorMessage: Translation, visitor?: boolean) => {
  if (errorMessage === 'ok') {
    return 'ok'
  }
  if (visitor) {
    if (errorMessage === 'zone_is_full_booked') {
      return 'error'
    }
    return 'ok'
  }
  if (errorMessage === 'zone_is_full_booked' || errorMessage === 'max_bookings_reached_provided_date') {
    return 'waiting'
  }
  return 'error'
}

const getMessageError = (errorMessage: Translation, isSelf: boolean, visitor?: boolean) =>
  `screens.planning.errorMessages.${
    errorMessage === 'zone_is_full_booked'
      ? visitor
        ? 'notAvailable'
        : 'waitingList'
      : errorMessage === 'max_bookings_reached_provided_date'
      ? 'waitingListPriority'
      : errorMessage === 'has_booking_applied_day'
      ? `userRefused${isSelf ? '_self' : ''}`
      : errorMessage === 'weekday_not_available'
      ? 'profilRefused'
      : errorMessage === 'max_days_in_future_exceeded'
      ? 'maxDaysFuture'
      : `maxProfilBookings${isSelf ? '_self' : ''}`
  }`

const updateBookingsStatusV2 = (bookings: ZapfloorMultipleZoneRecurrence[]) => {
  if (bookings.length > 0) {
    let weekNumber = getWeek(new Date(bookings[0].date))
    let bookingsLeftInfos: { [key: string]: number } = {}
    bookings.map((booking) => {
      const newStatusBookings = booking.zones.map((zone) => {
        const errorMessage = findPriorityError(zone.attributes.error_response)
        const bookingWeekNumber = getWeek(new Date(booking.date))
        if (bookingWeekNumber !== weekNumber) {
          weekNumber = bookingWeekNumber
          bookingsLeftInfos = {}
        }
        let bookingsLeft = 0
        if (bookingsLeftInfos[zone.attributes.access_group_id] === undefined) {
          bookingsLeftInfos[zone.attributes.access_group_id] =
            zone.attributes.max_days - zone.attributes.desks_booked_period
          bookingsLeft = zone.attributes.max_days - zone.attributes.desks_booked_period
        } else {
          bookingsLeft = bookingsLeftInfos[zone.attributes.access_group_id]
        }
        if (
          errorMessage !== 'has_booking_applied_day' &&
          errorMessage !== 'weekday_not_available' &&
          errorMessage !== 'user_limit_reached'
        ) {
          if (bookingsLeft > 0) {
            bookingsLeftInfos[zone.attributes.access_group_id] = bookingsLeft - 1
            if (
              errorMessage === 'user_limit_reached_manual' ||
              errorMessage === 'user_limit_reached_manual_waiting_full' ||
              errorMessage === 'user_limit_reached_manual_waiting_priority'
            ) {
              zone.attributes.error_response = [
                {
                  data: {
                    allowed_bookings: 0,
                    confirmed_bookings: 0,
                  },
                  status: errorMessage === 'user_limit_reached_manual' ? 'ok' : 'error',
                  translation:
                    errorMessage === 'user_limit_reached_manual'
                      ? 'ok'
                      : errorMessage === 'user_limit_reached_manual_waiting_full'
                      ? 'zone_is_full_booked'
                      : 'max_bookings_reached_provided_date',
                },
              ]
            }
            return booking
          } else {
            zone.attributes.error_response = [
              {
                data: {
                  allowed_bookings: 0,
                  confirmed_bookings: 0,
                },
                status: 'error',
                translation:
                  errorMessage === 'zone_is_full_booked' || errorMessage === 'user_limit_reached_manual_waiting_full'
                    ? 'user_limit_reached_manual_waiting_full'
                    : errorMessage === 'max_bookings_reached_provided_date' ||
                      errorMessage === 'user_limit_reached_manual_waiting_priority'
                    ? 'user_limit_reached_manual_waiting_priority'
                    : 'user_limit_reached_manual',
              },
            ]
            return booking
          }
        } else {
          return booking
        }
      })
      return newStatusBookings
    })
  }
  return bookings
}

const updateBookingsStatus = (bookings: ZapfloorZoneRecurrence[]) => {
  if (bookings.length > 0) {
    let weekNumber = getWeek(new Date(bookings[0].date))
    let bookingsLeftInfos: { [key: string]: number } = {}
    const newStatusBookings = bookings.map((booking) => {
      const errorMessage = findPriorityError(booking.zone.attributes.error_response)
      const bookingWeekNumber = getWeek(new Date(booking.date))
      if (bookingWeekNumber !== weekNumber) {
        weekNumber = bookingWeekNumber
        bookingsLeftInfos = {}
      }
      let bookingsLeft = 0
      if (bookingsLeftInfos[booking.zone.attributes.access_group_id] === undefined) {
        bookingsLeftInfos[booking.zone.attributes.access_group_id] =
          booking.zone.attributes.max_days - booking.zone.attributes.desks_booked_period
        bookingsLeft = booking.zone.attributes.max_days - booking.zone.attributes.desks_booked_period
      } else {
        bookingsLeft = bookingsLeftInfos[booking.zone.attributes.access_group_id]
      }
      if (
        errorMessage !== 'has_booking_applied_day' &&
        errorMessage !== 'weekday_not_available' &&
        errorMessage !== 'user_limit_reached'
      ) {
        if (bookingsLeft > 0) {
          bookingsLeftInfos[booking.zone.attributes.access_group_id] = bookingsLeft - 1
          if (
            errorMessage === 'user_limit_reached_manual' ||
            errorMessage === 'user_limit_reached_manual_waiting_full' ||
            errorMessage === 'user_limit_reached_manual_waiting_priority'
          ) {
            booking.zone.attributes.error_response = [
              {
                data: {
                  allowed_bookings: 0,
                  confirmed_bookings: 0,
                },
                status: errorMessage === 'user_limit_reached_manual' ? 'ok' : 'error',
                translation:
                  errorMessage === 'user_limit_reached_manual'
                    ? 'ok'
                    : errorMessage === 'user_limit_reached_manual_waiting_full'
                    ? 'zone_is_full_booked'
                    : 'max_bookings_reached_provided_date',
              },
            ]
          }
          return booking
        } else {
          booking.zone.attributes.error_response = [
            {
              data: {
                allowed_bookings: 0,
                confirmed_bookings: 0,
              },
              status: 'error',
              translation:
                errorMessage === 'zone_is_full_booked' || errorMessage === 'user_limit_reached_manual_waiting_full'
                  ? 'user_limit_reached_manual_waiting_full'
                  : errorMessage === 'max_bookings_reached_provided_date' ||
                    errorMessage === 'user_limit_reached_manual_waiting_priority'
                  ? 'user_limit_reached_manual_waiting_priority'
                  : 'user_limit_reached_manual',
            },
          ]
          return booking
        }
      } else {
        return booking
      }
    })
    return newStatusBookings
  }
  return bookings
}

const dateCanBeDisplayed = (date: Date) => {
  const now = new Date()
  const startWeekDate = now.getDate() - now.getDay() + 1
  const daysToAdd = isWeekend(now) ? 7 : 0
  const mondayOfFirstWeek = addDays(new Date(now.setDate(startWeekDate)), daysToAdd)
  const sundayOfLastWeek = addDays(mondayOfFirstWeek, 34) // 4 semaines + 6 jours

  return !isBefore(date, mondayOfFirstWeek) && isBefore(date, sundayOfLastWeek)
}

const getDatesInInterval = (start: Date, end: Date, selectedDays: number[]) =>
  !isSameDay(start, end) ? eachDayOfInterval({ start, end }).filter((d) => selectedDays.indexOf(getDay(d)) >= 0) : []

const getWeekDaysNumber = (weekDays: WeekDay[]) => weekDays.map((day) => weekDayToNumber(day))

const weekDayToNumber = (weekDay: WeekDay): number => {
  switch (weekDay) {
    case 'MONDAY':
      return 1
    case 'TUESDAY':
      return 2
    case 'WEDNESDAY':
      return 3
    case 'THURSDAY':
      return 4
    case 'FRIDAY':
      return 5
    default:
      return -1
  }
}

const getNextSelectableDate = (date: Date, maxDate: Date) => {
  if (!isWeekend(date)) {
    return date
  }
  const nextMonday = addBusinessDays(date, 1)
  if (isAfter(nextMonday, maxDate)) {
    return subBusinessDays(date, 1)
  }
  return nextMonday
}

const getClosestDate = (
  minDate: Date,
  maxDate: Date,
  currentDate: Date,
  datesToAvoid: Date[],
  avoidWeekend: boolean,
  minHour?: number,
  currentSiteHours?: number
) => {
  const realMinDate =
    minHour && isSameDay(minDate, new Date()) && (currentSiteHours || new Date().getHours()) >= minHour
      ? addDays(minDate, 1)
      : minDate
  const realCurrentDate = isBefore(currentDate, realMinDate) ? realMinDate : currentDate

  if (isAfter(realCurrentDate, maxDate) || isAfter(realMinDate, maxDate)) {
    return undefined
  }

  const filterFunction = (date: Date) =>
    !(!!datesToAvoid.find((d) => isSameDay(d, date)) || (avoidWeekend && isWeekend(date)))

  if (isSameDay(maxDate, realCurrentDate)) {
    const foundDate = eachDayOfInterval({ start: realMinDate, end: realCurrentDate }).reverse().find(filterFunction)
    if (!!foundDate) {
      return foundDate
    }
  } else if (isSameDay(realMinDate, realCurrentDate)) {
    const foundDate = eachDayOfInterval({ start: realCurrentDate, end: maxDate }).find(filterFunction)
    if (!!foundDate) {
      return foundDate
    }
  } else {
    const foundDate =
      eachDayOfInterval({ start: realMinDate, end: realCurrentDate }).reverse().find(filterFunction) ||
      eachDayOfInterval({ start: addDays(realCurrentDate, 1), end: maxDate }).find(filterFunction)
    if (!!foundDate) {
      return foundDate
    }
  }
}

const getReservationTimes = (reservationTime: ReservationTime) => {
  if (reservationTime === 'MORNING') {
    return { startTime: config.zapfloor.timeFromMorning, endTime: config.zapfloor.timeToMorning }
  }
  if (reservationTime === 'AFTERNOON') {
    return { startTime: config.zapfloor.timeFromAfternoon, endTime: config.zapfloor.timeToAfternoon }
  }
  return { startTime: config.zapfloor.timeFromAllDay, endTime: config.zapfloor.timeToAllDay }
}

const getBookingReservationTime = (timeFrom: string, timeTo: string) => {
  if (timeFrom === config.zapfloor.timeFromMorning && timeTo === config.zapfloor.timeToMorning) {
    return 'MORNING'
  }
  if (timeFrom === config.zapfloor.timeFromAfternoon && timeTo === config.zapfloor.timeToAfternoon) {
    return 'AFTERNOON'
  }
  return 'ALL_DAY'
}

const filteredBookings = (bookings: ZapfloorBooking[], date: Date) =>
  bookings
    .filter((booking) => isSameDay(getDayTimezone(booking.attributes.date_from), date))
    .sort((a, b) => {
      if (getBookingReservationTime(a.attributes.time_from, a.attributes.time_to) === 'MORNING') {
        return -1
      }
      if (getBookingReservationTime(b.attributes.time_from, b.attributes.time_to) === 'MORNING') {
        return 1
      }
      return 0
    })

const getUserListFiltered = (users: HotDeskBooking[], favoritesUsers: string[]) =>
  users
    .reduce((acc, curr) => {
      if (!acc.find((p) => p.user_id === curr.user_id)) {
        acc.push(curr)
      }
      return acc
    }, [] as HotDeskBooking[])
    .sort((a, b) => {
      if (favoritesUsers.includes(a.user_id) && favoritesUsers.includes(b.user_id)) {
        return a.user_lastname.localeCompare(b.user_lastname) || a.user_firstname.localeCompare(b.user_firstname)
      }
      if (favoritesUsers.includes(a.user_id)) {
        return -1
      }
      if (favoritesUsers.includes(b.user_id)) {
        return 1
      }
      return a.user_lastname.localeCompare(b.user_lastname) || a.user_firstname.localeCompare(b.user_firstname)
    })

const daysToExclude = (workingDays: string[]) => {
  const days = [0, 6]
  if (!workingDays.includes('Monday')) {
    days.push(1)
  }
  if (!workingDays.includes('Tuesday')) {
    days.push(2)
  }
  if (!workingDays.includes('Wednesday')) {
    days.push(3)
  }
  if (!workingDays.includes('Thursday')) {
    days.push(4)
  }
  if (!workingDays.includes('Friday')) {
    days.push(5)
  }
  return days
}

const isDayItemExcluded = (day: Date, workingDays: string[]) => {
  const weekDay = getDay(day)
  if (
    (!workingDays.includes('Monday') && weekDay === 1) ||
    (!workingDays.includes('Tuesday') && weekDay === 2) ||
    (!workingDays.includes('Wednesday') && weekDay === 3) ||
    (!workingDays.includes('Thursday') && weekDay === 4) ||
    (!workingDays.includes('Friday') && weekDay === 5)
  ) {
    return true
  }
  return false
}
const getDayTimezone = (date: string) => {
  const formattedDate = new Date(date)
  return new Date(formattedDate.valueOf() + formattedDate.getTimezoneOffset() * 60 * 1000)
}

export const utcDateToTimezoneSite = (date: string, timezone: string) => utcToZonedTime(new Date(date), timezone)

export const getCurrentTimeInCurrentSite = (timezone: string, currentDate: Date) => {
  // TimeZone de l'endroit actuel
  const currentLocationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  if (!currentLocationTimeZone) {
    return undefined
  }

  // Convertir l'heure actuelle en UTC
  const utcDate = zonedTimeToUtc(currentDate, currentLocationTimeZone)
  // TimeZone de l'endroit dont on veut obtenir l'heure
  const targetLocationTimeZone = timezone
  // Convertir l'heure UTC en heure locale de l'endroit souhaité
  const targetDate = utcToZonedTime(utcDate, targetLocationTimeZone)
  return targetDate.getHours()
}

const getMaxDate = (minDate: Date, maxDays: number, maxDaysInFuture?: number, maxDeskBookingDays?: number) =>
  addDays(setHours(setMinutes(minDate, 59), 23), maxDaysInFuture || maxDeskBookingDays || maxDays)

const sortDesksNames = (deskAreas: ZapfloorDeskArea[]) => {
  const sortedDesks = deskAreas.sort((a, b) => {
    // Extraire le préfixe alphabétique et le numéro de chaque chaîne
    const [prefixA, numberA] = a.area.name.split(/\s/)
    const [prefixB, numberB] = b.area.name.split(/\s/)

    // Comparaison par ordre alphabétique des préfixes
    const prefixComparison = prefixA.localeCompare(prefixB)

    // Si les préfixes sont différents, retourner le résultat de la comparaison
    if (prefixComparison !== 0) {
      return prefixComparison
    }

    // Si les préfixes sont identiques, comparer les numéros en tant que nombres
    const numA = parseInt(numberA) || 0 // Si numberA n'est pas un nombre valide, le remplacer par 0
    const numB = parseInt(numberB) || 0 // Si numberB n'est pas un nombre valide, le remplacer par 0

    return numA - numB // Comparaison des numéros en tant que nombres
  })
  return sortedDesks
}

export {
  dateCanBeDisplayed,
  findPercentageOccupation,
  findColorOccupation,
  findIconName,
  findAvailabilityIcon,
  findAvailabilityColor,
  findPriorityError,
  getDatesInInterval,
  getWeekDaysNumber,
  weekDayToNumber,
  filterAvailableBookings,
  filterNotAvailableBookings,
  filterAvailableBookingsV2,
  filterNotAvailableBookingsV2,
  updateBookingsStatus,
  updateBookingsStatusV2,
  getDatesWeekSelected,
  getMessageError,
  findDay,
  getDayOfWeek,
  getStatusErrorMessage,
  filteredDays,
  getNextSelectableDate,
  getClosestDate,
  getReservationTimes,
  getBookingReservationTime,
  filteredBookings,
  getUserListFiltered,
  daysToExclude,
  isDayItemExcluded,
  getDayTimezone,
  getMaxDate,
  sortDesksNames,
}
