import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getAllSites: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<SiteListV6>(userType, `${config.globalAPIVersion}/sites`, 'GET', { Authorization: `Bearer ${token}` })
    ),
  getAllSitesLight: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<SiteLightListV6>(userType, `${config.globalAPIVersion}/sites/light`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getSite: (id: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<SiteV6>(userType, `${config.globalAPIVersion}/sites/${id}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getFeatures: (id: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<FeatureListV4>(userType, `${config.globalAPIVersion}/sites/${id}/features`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getUserSites: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<PersonalSitesV5>(userType, `${config.globalAPIVersion}/account/me/sites`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
