import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  registerUser: (siteId: string, userType: UserType, formBody: VisitRegisterFormV6) =>
    assureToken(userType).then((token) =>
      Webservice<VisitV6>(
        userType,
        `${config.globalAPIVersion}/visits/register?siteId=${siteId}`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        formBody
      )
    ),
  cancelVisit: (siteId: string, userType: UserType, visitId: string) =>
    assureToken(userType).then((token) =>
      Webservice<void>(userType, `${config.globalAPIVersion}/visits/${visitId}?siteId=${siteId}`, 'DELETE', {
        Authorization: `Bearer ${token}`,
      })
    ),
  modifyVisit: (visitId: string, siteId: string, userType: UserType, formBody: VisitRegisterFormV6) =>
    assureToken(userType).then((token) =>
      Webservice<VisitV6>(
        userType,
        `${config.globalAPIVersion}/visits/${visitId}?siteId=${siteId}`,
        'PUT',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        formBody
      )
    ),
}

export default api
